import {goToByScroll} from './go-to-by-scroll';
import {utils} from "./utils";
import {bindLinkToItem} from "./bind-link-to-item";
//import { gsap } from '../libs/gsap.min';
//import { ScrollTrigger } from '../libs/ScrollTrigger.min';

// Initialize a new Lenis instance for smooth scrolling
const lenis = new Lenis();

// Listen for the 'scroll' event and log the event data to the console
/*lenis.on('scroll', (e) => {
	console.log(e);
});*/

// Synchronize Lenis scrolling with GSAP's ScrollTrigger plugin
lenis.on('scroll', ScrollTrigger.update);

// Add Lenis's requestAnimationFrame (raf) method to GSAP's ticker
// This ensures Lenis's smooth scroll animation updates on each GSAP tick
gsap.ticker.add((time) => {
	lenis.raf(time * 1000); // Convert time from seconds to milliseconds
});

// Disable lag smoothing in GSAP to prevent any delay in scroll animations
gsap.ticker.lagSmoothing(0);


/**
 * Images parallax
 */
gsap.utils.toArray(".parallax-img-container").forEach(function(container) {
	let image = container.querySelector("img");

	gsap.to(image, {
		y: () => image.offsetHeight - container.offsetHeight,
		ease: "none",
		scrollTrigger: {
			trigger: container,
			scrub: true,
			pin: false,
			//markers: true,
			invalidateOnRefresh: true
		},
	});
});


/**
 * Toggle sub menu when clicking on the whole anchor
 */
document.querySelectorAll('.menu-item-has-children > a').forEach((el) => {

	el.addEventListener('click', function (e) {
		e.preventDefault();
		e.stopPropagation();

		let toggleMenuItem = el.querySelector('.toggle_menu_item');

		toggleMenuItem.click();
	});
});

/**
 * Change sticky image on scroll
 */
/*const stickyImagesSectionText = gsap.utils.toArray('.module-sticky-texts-section__text');
const stickyImagesWrapper     = document.querySelector('.module-sticky-texts-section__img-wrapper');

// move first item to the end
if (stickyImagesWrapper !== null)
{
	let stickyImagesActiveItem = stickyImagesWrapper.querySelector('[data-id="1"]');
	stickyImagesWrapper.appendChild(stickyImagesActiveItem);
}

stickyImagesSectionText.forEach((item) => {

	ScrollTrigger.create({
		trigger:  item,
		start:    () => 'top center',
		end:      () => 'bottom center',
		onToggle: ({progress, direction, isActive}) => {

			if (isActive)
			{
				const index = [...item.parentNode.children].indexOf(item) + 1;

				let stickyImagesActiveItem = stickyImagesWrapper.querySelector('[data-id="' + index + '"]');

				// move active item to the end, if it's not already the last
				if (stickyImagesActiveItem.nextElementSibling !== null)
				{
					stickyImagesWrapper.appendChild(stickyImagesActiveItem);
				}
			}
		},
		//markers:     true
	});
});*/


/**
 * Open accordion after clicking on index anchor
 * @type {NodeListOf<Element>}
 */
const accodionIndexAnchors = document.querySelectorAll('.accordion-index a');

accodionIndexAnchors.forEach((el, i) => {
	el.addEventListener('click', function (e) {

		const id = el.hash.substring(1);

		const accordionElement = document.getElementById(id);

		if (accordionElement !== null)
		{
			e.preventDefault();

			let accordionToggle = accordionElement.querySelector('.module-accordion__toggle');

			if (!accordionToggle.classList.contains('active'))
			{
				accordionToggle.click();
			}
		}
	});
});


const moduleAltreSoluzioniVideos = document.querySelectorAll('.module-altre-soluzioni video');

moduleAltreSoluzioniVideos.forEach((el, i) => {

	if (matchMedia('(any-pointer: coarse)').matches)
	{
		el.setAttribute('autoplay', 'true');
	}
	else
	{
		el.addEventListener("mouseover", function() {
			this.play();
		});

		el.addEventListener("mouseleave", function() {
			this.pause();
		});
	}
});


/**
 * Pin Soluzione Dettaglio Intro image
 * @type {Element}
 */
let soluzioneDettaglioIntro = document.querySelector('.page-template-template-soluzione-dettaglio .intro');

if (soluzioneDettaglioIntro !== null)
{
	ScrollTrigger.create({
		trigger:  soluzioneDettaglioIntro,
		pin: soluzioneDettaglioIntro.querySelector('.intro__bg'),
		start:    () => 'top top',
		end:      () => 'bottom bottom',
		//markers:     true
	});

}


/**
 * Force ALL ScrollTrigger instances to re-calculate its start and end values after each image is loaded
 */
let images = document.querySelectorAll('img');

Promise.all(Array.from(images).map(img => {

	if (img.complete)
	{
		return Promise.resolve(img.naturalHeight !== 0);
	}

	return new Promise(resolve => {

		img.addEventListener('load', () => {

			// refresh here
			ScrollTrigger.refresh();

			resolve(true);
		});

		img.addEventListener('error', () => resolve(false));

	});

})).then(results => {

	if (results.every(res => res))
	{
		//console.log('all images loaded successfully');
	}
	else
	{
		//console.log('some images failed to load, all finished loading');
	}

});



/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {


});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {
	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const event = new CustomEvent('resizeEnd');
			window.dispatchEvent(event);
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {

	ScrollTrigger.getAll().forEach(t => t.refresh());

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/