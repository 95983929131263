import {utils} from "./utils";

//================================= TOGGLE MENU

const body = document.querySelector('body');

document.addEventListener('click', function (e) {
	const menuToggle = document.getElementById('menu-toggle');

	// check if menuToggle exists
	if (menuToggle === null)
	{
		return;
	}

	// return if the clicked element is not #menu-toggle nor is contained in #menu-toggle
	if (e.target !== menuToggle && !menuToggle.contains(e.target))
	{
		return;
	}

	menuToggle.classList.toggle('tcon-transform');

	// open/close mobile menu
	body.classList.toggle('menu-open');

	/*let toggleLabel = menuToggle.querySelector('.tcon-label');

	if (toggleLabel !== null)
	{
		toggleLabel.textContent = (body.classList.contains('menu-open')) ? toggleLabel.dataset.labelClose : toggleLabel.dataset.labelMenu;
	}*/

	utils.slideToggle(document.getElementById('site-navigation'), 600);
});

let menuClose = document.querySelector('#menu-close');

if (menuClose !== null)
{
	menuClose.addEventListener('click', function () {
		// close mobile menu
		body.classList.remove('menu-open');
	});
}


//================================= ADD SUB-MENU TOGGLES

document.querySelectorAll('.menu-item-has-children > a').forEach((el) => {
	const toggleMenuItem = document.createElement('span');
	toggleMenuItem.classList.add('toggle_menu_item');
	el.appendChild(toggleMenuItem);

	toggleMenuItem.addEventListener('click', function(e){
		e.preventDefault();
		e.stopPropagation();

		const menuItem = this.closest('.menu-item');

		utils.toggleClasses(menuItem, 'open');
		utils.slideToggle(menuItem.querySelector('.sub-menu'));
	});
});