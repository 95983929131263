let gallerySlideshowSwiper = new Swiper('.gallery-display-as-slideshow .swiper', {
	slidesPerView: 'auto',
	spaceBetween:  16,
	speed:         500,
	autoHeight:    true,
	pagination:    {
		el:        ".swiper-pagination",
		clickable: true
	},
	/*loop:          true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
});



let contentsSlideshowTextsSwiper = new Swiper('.module-content-slideshow .swiper', {
	slidesPerView: 'auto',
	loop:          false,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination:    {
		el:        ".swiper-pagination",
		clickable: false
	},
	grabCursor: true,
	effect: "creative",
	creativeEffect: {
		prev: {
			shadow: true,
			translate: [0, 0, -400],
			opacity: 0
		},
		next: {
			translate: ["100%", 0, 0],
		},
	},
});