import {lerp, getMousePos} from './utils';

// Track the mouse position
let mouse = {x: 0, y: 0};
window.addEventListener('pointermove', ev => mouse = getMousePos(ev));

export default class Cursor {
	constructor(el) {
		this.DOM = {el: el};
		this.DOM.el.style.opacity = 0;

		this.bounds = this.DOM.el.getBoundingClientRect();

		this.renderedStyles = {
			tx:      {previous: 0, current: 0, amt: 0.1},
			ty:      {previous: 0, current: 0, amt: 0.1},
			scale:   {previous: 1, current: 1, amt: 0.1},
			opacity: {previous: 1, current: 1, amt: 0.1}
		};

		this.onMouseMoveEv = () => {
			this.renderedStyles.tx.previous = this.renderedStyles.tx.current = mouse.x - this.bounds.width / 2;
			this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = mouse.y - this.bounds.height / 2;

			gsap.to(this.DOM.el, {duration: 0.9, ease: 'power3.out', opacity: 1});

			requestAnimationFrame(() => this.render());

			window.removeEventListener('pointermove', this.onMouseMoveEv);
		};

		window.addEventListener('pointermove', this.onMouseMoveEv);
	}

	enterActiveElement() {
		this.renderedStyles['scale'].current = 2.5;
	}

	leaveActiveElement() {
		this.renderedStyles['scale'].current = 1;
	}

	render() {
		this.renderedStyles['tx'].current = mouse.x - this.bounds.width / 2;
		this.renderedStyles['ty'].current = mouse.y - this.bounds.height / 2;

		for (const key in this.renderedStyles)
		{
			this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
		}

		this.DOM.el.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px) scale(${this.renderedStyles['scale'].previous})`;

		this.DOM.el.style.opacity = this.renderedStyles['opacity'].previous;

		requestAnimationFrame(() => this.render());
	}
}